import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {EMPLOYEE_ATTENDANCE, EMPLOYEE_ATTENDANCE_API_URL, GET_ALL_EMPLOYEE_ATTENDANCE_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  EmployeeAttendanceErrors       : {},
  permission: permission?.employee_attendance ?? [],
  EmployeeAttendanceShowMessage  : null,
  EmployeeAttendanceResult       : [],
  EmployeeAttendanceButtonSpinner : false,
  EmployeeAttendanceTableLoading : true,
  EmployeeAttendanceAddDrawer    : false,
  EmployeeAttendanceEditData     : [],
  EmployeeAttendanceButtonAndModelLabel  : setLocale('employeeattendance.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewEmployeeAttendanceData: [],
    ViewEmployeeAttendanceLoader: true,
    DrawerStatus: 0,
};

export const createEmployeeAttendance = createAsyncThunk(
    "createEmployeeAttendance",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, EMPLOYEE_ATTENDANCE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getEmployeeAttendance = createAsyncThunk(
    "getEmployeeAttendance",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_EMPLOYEE_ATTENDANCE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewEmployeeAttendance = createAsyncThunk(
  "viewEmployeeAttendance",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, EMPLOYEE_ATTENDANCE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteEmployeeAttendance = createAsyncThunk(
  "deleteEmployeeAttendance",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, EMPLOYEE_ATTENDANCE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageEmployeeAttendanceSlice = createSlice({
  name: EMPLOYEE_ATTENDANCE,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.EmployeeAttendanceErrors = {};
    },
    EmployeeAttendanceAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.EmployeeAttendanceButtonSpinner = false;
      }
      state.EmployeeAttendanceAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.EmployeeAttendanceEditData    = [];
      state.EmployeeAttendanceButtonAndModelLabel = setLocale('employeeattendance.add');
    },
    EmployeeAttendanceEditWithDrawerStatus: (state, action) => {
      state.EmployeeAttendanceAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.EmployeeAttendanceEditData = action.payload.data;
      state.EmployeeAttendanceButtonAndModelLabel = setLocale('employeeattendance.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEmployeeAttendance.pending, (state, action) => {
        state.DrawerStatus = 0
        state.EmployeeAttendanceButtonSpinner = true;
      }).addCase(createEmployeeAttendance.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.EmployeeAttendanceButtonSpinner = false;
        state.EmployeeAttendanceErrors = {}
      }).addCase(createEmployeeAttendance.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.EmployeeAttendanceShowMessage = true;
        state.EmployeeAttendanceButtonSpinner = false;
        state.EmployeeAttendanceErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getEmployeeAttendance.pending, (state, action) => {
        state.EmployeeAttendanceButtonSpinner = true;
        state.EmployeeAttendanceTableLoading = true;
      }).addCase(getEmployeeAttendance.fulfilled, (state, action) => {
        state.EmployeeAttendanceButtonSpinner = false;
        state.EmployeeAttendanceTableLoading = false;
        state.EmployeeAttendanceResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getEmployeeAttendance.rejected, (state, action) => {
        state.EmployeeAttendanceShowMessage = true; // Set the showMessage flag to display the errors
        state.EmployeeAttendanceButtonSpinner = false;
        state.EmployeeAttendanceTableLoading = false;
        state.EmployeeAttendanceErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewEmployeeAttendance.pending, (state, action) => {
        state.ViewEmployeeAttendanceLoader = true;
        state.ViewEmployeeAttendanceData = [];
      }).addCase(viewEmployeeAttendance.fulfilled, (state, action) => {
        state.ViewEmployeeAttendanceLoader = false;
        state.ViewEmployeeAttendanceData = action.payload;
      }).addCase(viewEmployeeAttendance.rejected, (state, action) => {
        state.ViewEmployeeAttendanceLoader = false;
        state.ViewEmployeeAttendanceData = [];
      })
      .addCase(deleteEmployeeAttendance.pending, (state, action) => {
        state.EmployeeAttendanceTableLoading = true;
      }).addCase(deleteEmployeeAttendance.fulfilled, (state, action) => {
        state.EmployeeAttendanceTableLoading = false;
      }).addCase(deleteEmployeeAttendance.rejected, (state, action) => {
        state.EmployeeAttendanceTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, EmployeeAttendanceAddDrawerStatus, EmployeeAttendanceEditWithDrawerStatus, updateSortFilters } = manageEmployeeAttendanceSlice.actions;

export default manageEmployeeAttendanceSlice.reducer;
